/* eslint-disable */
import { ActionTypes } from "../actions/index";

export const initialData = {
    modal:{
        show:"",
        data:null,
    },
    offcanvas:{
        show:"",
        data:null,
    },
    age_verify:false,
    warn_widgets:false,
    showChat: false,
}

export const allReducers = (state = initialData, action) => {
    switch(action?.type) {
        case ActionTypes?.SET_SHOW_PREVIEW_POPUP:
            return{
                ...state,
                modal:{
                    show:action?.payload?.show,
                    data:action?.payload?.data,
                }
            }
        case ActionTypes?.SET_STORE_AGE_VERIFY:
            return{
                ...state,
                age_verify:action?.payload,
                modal:{
                    show:action?.payload?"":"AGE_VERIFY",
                }
            }
        case ActionTypes?.SET_SHOW_OFFCANVAS_POPUP:
            return{
                ...state,
                offcanvas:{
                    show:action?.payload?.show,
                    data:action?.payload?.data,
                }
            }
        case ActionTypes?.SET_STORE_WARN_WIDGETS:
            return{
                ...state,
                warn_widgets:action?.payload,
                modal:{
                    show:action?.payload?"":"WARN_WIDGETS",
                }
            }
        case ActionTypes?.SET_STORE_SHOW_CHAT_BOT:
            return{
                ...state,
                showChat: action?.payload
            }
        default:
            return state;
    }
}