import PropTypes from "prop-types";
const Typography = (props) =>{
    let Elements = "p";
    if(props?.as){
        Elements = props?.as;
    }
    let  fontWeight = props?.fontWeight;
    // if(props?.as === "h1"||props?.as === "h2"||props?.as === "h3"||props?.as === "h4"||props?.as === "h5"||props?.as === "h6"){
    //     fontWeight = "bold" 
    // }
    if(props?.dangerouslySetInnerHTML){
        <Elements hidden={props?.hidden} className={`text title-${props?.title} font-${props?.size} ${props?.styleText?"text-style":"text-normal"} ${props?.head?"title-head":""} title-head-${props?.head} ${props?.className} ${props?.theme} ${fontWeight?`fc-w-${fontWeight}`:""}`}  style={{fontWeight:fontWeight}} >{props?.children}</Elements>
    }
    return(
        <Elements hidden={props?.hidden} className={`text title-${props?.title} font-${props?.size} ${props?.styleText?"text-style":"text-normal"} ${props?.head?"title-head":""} title-head-${props?.head} ${props?.className} ${props?.theme} ${fontWeight?`fc-w-${fontWeight}`:""}`}  style={{fontWeight:fontWeight}} >{props?.children}</Elements>
    )
}
Typography.propTypes = {
    className: PropTypes.any,
    as:PropTypes.string,
    theme:PropTypes?.any,
    fontWeight:PropTypes?.any,
    dangerouslySetInnerHTML:PropTypes?.any,
    head:PropTypes?.number,
    styleText:PropTypes?.bool,
    size:PropTypes?.number,
    title:PropTypes?.string,
    hidden:PropTypes?.bool
}
Typography.defaultProps = {
    className: '',
    as:`p`,
    theme:"",
    fontWeight:"",
    dangerouslySetInnerHTML:"",
    head:0,
    styleText:false,
    size:0,
    title:"",
    hidden:false
}
export default Typography;
