export const ActionTypes = {
    SET_SHOW_PREVIEW_POPUP: "SET_SHOW_PREVIEW_POPUP",
    SET_SHOW_OFFCANVAS_POPUP:"SET_SHOW_OFFCANVAS_POPUP",
    SET_STORE_AGE_VERIFY:"SET_STORE_AGE_VERIFY",
    SET_STORE_WARN_WIDGETS:"SET_STORE_WARN_WIDGETS",
    SET_STORE_SHOW_CHAT_BOT:"SET_STORE_SHOW_CHAT_BOT",
}
export const setShowModalPreview = (payload, data) =>{
    return {
        type:ActionTypes?.SET_SHOW_PREVIEW_POPUP,
        payload:{show:payload, data:data?data:payload},
    }
}
export const setShowOffCanvas = (payload, data) =>{
    return {
        type:ActionTypes?.SET_SHOW_OFFCANVAS_POPUP,
        payload:{show:payload, data:data?data:payload},
    }
}
export const setStoreAgeVerify = (payload) =>{
    return {
        type:ActionTypes?.SET_STORE_AGE_VERIFY,
        payload:payload,
    }
}
export const setStoreWarnWidgets = (payload) =>{
    return {
        type:ActionTypes?.SET_STORE_WARN_WIDGETS,
        payload:payload,
    }
}
export const setStoreShowChatBot = (payload) =>{
    return {
        type:ActionTypes?.SET_STORE_SHOW_CHAT_BOT,
        payload:payload,
    }
}