import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import LoaderImage from "./assets/img/home/152x152.png";
import Typography from './component/common/Typography';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './redux/store';
import { Provider } from 'react-redux';
import axios from 'axios';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const App = lazy(() => import('./App'));

const root = ReactDOM.createRoot(document.getElementById('root'));
const BrijLoader = () =>{
  return(
    <div className='suspense_loader'>
      <div className='loader-body'>
        <img alt='fellame' src={LoaderImage} />
        <Typography as={"h5"} theme={"primary"}>brijmount.com</Typography>
      </div>
    </div>
  )
}
root.render(
  <Suspense fallback={<BrijLoader/>}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App  loader={BrijLoader}/>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
